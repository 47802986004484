<template>
  <div
    :class="{'company-page-sidebar--no-logo': !hasLogo}"
    class="company-page-sidebar"
    data-testid="NewsroomSidebar"
  >
    <newsroom-navigation
      :newsroom="newsroom"
      class="is-hidden-tablet"
    />

    <newsroom-edit-social-links-data-provider
      #default="{ firstHandlePerType }"
      :newsroom-id="newsroom.id"
    >
      <div class="social-links">
        <newsroom-social-links-link
          v-for="handle in firstHandlePerType"
          :key="handle.id"
          :value="handle.handle"
          :type="handle.type"
        />

        <div class="m-l-a is-flex is-align-middle">
          <newsroom-rss :newsroom="newsroom" />
          <follow-newsroom-button :newsroom="newsroom" />
        </div>
      </div>
    </newsroom-edit-social-links-data-provider>

    <div class="company-info m-b-m">
      <p
        class="description m-t-m m-b-l break-words"
        data-testid="NewsroomSidebar_Description"
        v-html="description"
      />

      <div data-testid="NewsroomSidebar_ContactInfo">
        <div><strong>{{ $t('components.company_sidebar.contact_info') }}</strong></div>
        <div class="company-info__name">
          {{ newsroom.contact_name }}
        </div>
        <div class="company-info__role has-text-muted">
          {{ newsroom.contact_role }}
        </div>

        <div class="company-info__email">
          <a :href="`mailto:${ newsroom.contact_email }`">
            {{ newsroom.contact_email }}
          </a>
        </div>

        <div
          v-if="newsroom.contact_phone"
          class="company-info__phone"
        >
          <a :href="`tel:${ newsroom.contact_phone }`">{{ newsroom.contact_phone }}</a>
        </div>

        <br>

        <div><strong>{{ $t('components.company_sidebar.address') }}</strong></div>

        <div
          v-if="newsroom.address"
          class="company-info__address"
        >
          {{ newsroom.address }}
        </div>
        <website-link
          :href="newsroom.website"
          class="company-info__website"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NewsroomNavigation from '@/components/newsroom/NewsroomNavigation.vue'
import NewsroomRss from '@/components/newsroom/NewsroomRss.vue'
import NewsroomSocialLinksLink from './NewsroomSocialLinksLink'
import NewsroomEditSocialLinksDataProvider from '../newsroomEdit/NewsroomEditSocialLinksDataProvider'

export default {
  components: {
    NewsroomEditSocialLinksDataProvider,
    NewsroomSocialLinksLink,
    NewsroomRss,
    NewsroomNavigation
  },
  props: {
    newsroom: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasLogo () {
      return !!this.getLogo
    },
    getLogo () {
      return this.newsroom.logo.cropped
    },
    description () {
      return this.newsroom.description ? this.newsroom.description.replace(/\n/g, '<br/>') : ''
    }
  }
}
</script>
