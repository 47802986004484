<template>
  <ul class="NewsroomNavigation">
    <slot
      :hasVideo="hasVideo"
      :hasAssets="hasAssets"
      :hasStories="hasStories"
      :hasAboutInfo="hasAboutInfo"
      :hasSocialFeed="hasSocialFeed"
    />
  </ul>
</template>

<script>
import { newsroomDataChecks } from '@/shared/newsroomUtilities'

/**
 * @module NewsroomNavigationBase
 */
export default {
  name: 'NewsroomNavigationBase',
  props: {
    newsroom: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...newsroomDataChecks
  }
}
</script>

<style lang="scss">
@import "~utils";

.NewsroomNavigation {
  border-color: $hf__color-gray-darker;
  border-width: 1px 0 1px 0;
  border-style: solid;
  display: flex;
  flex-flow: row wrap;
  //margin-bottom: 1.5rem;
  font-size: 0.9rem;

  @include tablet {
    border-bottom-width: 1px;
    width: 100%;
    margin-bottom: 0;
    font-size: 1.1rem;
  }
}
</style>
