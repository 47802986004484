<template>
  <a
    :href="link"
    :disabled="!value"
    :class="[{ [`is-${type}`]: value, 'is-grey-light': !value }]"
    :data-testid="`NewsroomSidebar_SocialLinks_${type}`"
    class="NewsroomSocialLinksLink button is-round"
    target="_blank"
    @click="(!value && $event.preventDefault())"
  >
    <span class="icon">
      <v-icon :type="type" />
    </span>
  </a>
</template>

<script>
/**
 * @module NewsroomSocialLinksLink
 */
export default {
  name: 'NewsroomSocialLinksLink',
  props: {
    value: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    link () {
      if (!this.value) {
        return null
      }
      switch (this.type) {
        case 'facebook':
          return 'https://facebook.com/' + this.value
        case 'instagram':
          return 'https://instagram.com/' + this.value
        case 'linkedin':
          return 'https://linkedin.com/company/' + this.value
        case 'pinterest':
          return 'https://pinterest.com/' + this.value
        case 'twitter':
          return 'https://twitter.com/' + this.value
        case 'youtube':
          return 'https://youtube.com/channel/' + this.value
        default:
          return null
      }
    }
  }
}
</script>
