<template>
  <router-link
    :to="newsroomLink"
    class="NewsroomEditPreviewButton"
    data-testid="editLink"
  >
    {{ editLinkText }}
  </router-link>
</template>

<script>
/**
 * Generates a button that switches between Newsroom preview and edit
 * @module NewsroomEditPreviewButton
 */
export default {
  name: 'NewsroomEditPreviewButton',
  props: {
    newsroom: {
      type: Object,
      required: true
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * Returns a link to newsroom edit or the newsroom depending in the editMode
     * @returns {{name: string, params: {newsroom_slug: string}}}
     */
    newsroomLink () {
      return {
        name: this.editMode ? 'newsroom.stories' : 'newsroom.edit',
        params: { newsroomSlug: this.newsroom.slug }
      }
    },

    editLinkText () {
      if (this.newsroom.is_published) {
        return this.editMode ? this.$t('components.newsroom_admin_bar.view_live') : this.$t('components.newsroom_admin_bar.edit')
      } else {
        return this.editMode ? this.$t('components.newsroom_admin_bar.preview') : this.$t('components.newsroom_admin_bar.edit')
      }
    }
  }
}
</script>
