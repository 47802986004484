import { mapActions, mapMutations, mapState } from 'vuex'
import { generateComputedSetterWithMethod } from '@hypefactors/shared/js/utils/componentUtilities'

const SOCIAL_NETWORK_GROUPS = () => ({
  facebook: [],
  twitter: [],
  linkedin: [],
  instagram: [],
  youtube: []
})

export default {
  name: 'NewsroomEditSocialLinksDataProvider',

  props: {
    newsroomId: {
      type: String,
      required: true
    }
  },

  computed: {
    groupedHandles () {
      return this.handlesLocal.reduce((all, handle) => {
        all[handle.type].push(handle)
        return all
      }, SOCIAL_NETWORK_GROUPS())
    },
    firstHandlePerType () {
      return Object.values(this.groupedHandles)
        .filter(g => !!g.length)
        .map(g => g[0])
    },
    ...mapState('newsroomSocialHandles', ['handles']),
    handlesLocal: generateComputedSetterWithMethod('handles', 'STORE_HANDLES')
  },

  watch: {
    newsroomId: {
      immediate: true,
      handler: 'fetchSocialHandles'
    }
  },

  methods: {
    ...mapMutations('newsroomSocialHandles', ['STORE_HANDLES']),
    ...mapActions('newsroomSocialHandles', ['fetchSocialHandles']),
    handleRemove (handleId) {
      const index = this.handlesLocal.findIndex(h => h.id === handleId)
      this.handlesLocal.splice(index, 1)
    },
    handleCreate (handle) {
      this.handlesLocal.push(handle)
    },
    handleUpdate (handle) {
      const index = this.handlesLocal.findIndex(h => h.id === handle.id)
      this.handlesLocal.splice(index, 1, handle)
    }
  },

  render (h) {
    return this.$scopedSlots.default({
      handleRemove: this.handleRemove,
      handleCreate: this.handleCreate,
      handleUpdate: this.handleUpdate,
      groupedHandles: this.groupedHandles,
      handles: this.handles,
      firstHandlePerType: this.firstHandlePerType
    })
  }
}
