import { mapGetters } from 'vuex'

export const newsroomDataChecks = {
  ...mapGetters('personalNewsrooms', ['userOwnsNewsroom']),
  /**
   * Determines if the company has assets.
   *
   * @return Boolean
   */
  hasAssets () {
    return !this.$isEmpty(this.newsroom) && this.newsroom.files.length > 0
  },

  /**
   * Determines if the newsroom has social handles.
   *
   * @return Boolean
   */
  hasSocialFeed () {
    return this.newsroom.social_handles_count > 0
  },

  /**
   * Determines if a newsroom has videos
   * @return {boolean}
   */
  hasVideo () {
    return this.newsroom.videos.length > 0 && !!this.newsroom.videos[0].id
  },

  /**
   * Determines if a newsroom has any stories
   * @return {boolean}
   */
  hasStories () {
    return this.newsroom.stories_count > 0
  },

  hasAboutInfo () {
    return !!(this.hasAssets || this.hasSocialFeed || this.hasVideo)
  },

  /**
   * Checks if the user is the owner of the newsroom
   * @returns {boolean}
   */
  isOwner () {
    return this.userOwnsNewsroom(this.newsroom.slug)
  }
}
