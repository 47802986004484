<template>
  <div v-if="newsroom" class="company-page">
    <div
      v-if="!newsroom.is_published"
      class="has-background-info has-text-weight-semibold p-v-s has-text-centered"
    >
      <div class="container">
        {{ $t('errors.newsroom_not_published') }}
      </div>
    </div>

    <newsroom-cover :newsroom="newsroom" />

    <div class="container">
      <div class="columns">
        <div class="column is-one-quarter is-position-relative">
          <newsroom-logo :newsroom="newsroom" />
        </div>

        <div class="column is-three-quarters">
          <alternative-newsroom-picker :newsroom="newsroom" />
          <div class="columns company-page__name is-aligned-middle">
            <div class="column">
              <h1
                class="has-text-centered-mobile"
                data-testid="Newsroom_NewsroomName"
              >
                {{ newsroom.name }}
              </h1>
            </div>
            <div class="column is-narrow company-page__head-actions">
              <newsroom-edit-preview-button
                v-if="isOwner"
                :newsroom="newsroom"
                class="button is-primary"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-one-quarter">
          <newsroom-sidebar :newsroom="newsroom" />
        </div>

        <div class="column is-three-quarters company-page__content-wrap">
          <newsroom-navigation
            :newsroom="newsroom"
            data-testid="DesktopNavigation"
            class="is-hidden-mobile"
          />

          <transition name="fade" mode="out-in">
            <router-view
              :newsroom="newsroom"
              class="company-page__content"
            />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Newsroom } from '@hypefactors/shared/js/models/Newsroom'

import { newsroomDataChecks } from '@/shared/newsroomUtilities'
import { NewsroomService } from '@hypefactors/shared/js/services/api/NewsroomService'
import { changePageTitle, changePageDescription, changeOpenGraphTag } from '@/shared/utils'

import NewsroomLogo from '@/components/newsroom/NewsroomLogo'
import NewsroomCover from '@/components/newsroom/NewsroomCover'
import NewsroomSidebar from '@/components/newsroom/NewsroomSidebar'
import NewsroomNavigation from '@/components/newsroom/NewsroomNavigation'
import NewsroomEditPreviewButton from '@/components/newsroomEdit/NewsroomEditPreviewButton'
import AlternativeNewsroomPicker from '@/components/newsroom/AlternativeNewsroomPicker'

export default {
  name: 'Newsroom',

  components: {
    AlternativeNewsroomPicker,
    NewsroomEditPreviewButton,
    NewsroomCover,
    NewsroomSidebar,
    NewsroomNavigation,
    NewsroomLogo
  },

  props: {
    newsroomSlug: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      newsroom: null,
      cancelToken: null
    }
  },

  computed: {
    ...newsroomDataChecks
  },

  mounted () {
    this.fetch()
  },

  async beforeRouteUpdate (to, from, next) {
    // if from and to is different and not equal to newsroom.edit
    if (to.name !== 'newsroom.stories' || (to.params.newsroomSlug === from.params.newsroomSlug)) {
      return next()
    }
    await this.fetch(to.params.newsroomSlug)
    next()
  },

  beforeDestroy () {
    this.cancelToken && this.cancelToken.cancel()
  },

  methods: {
    fetch (slug) {
      slug = slug || this.newsroomSlug

      this.startLoad()
      this.cancelToken && this.cancelToken.cancel()
      this.cancelToken = this.$api.cancelToken()

      return NewsroomService.fetchNewsroom(slug, {
        params: { include: 'brand,categories,contacts,country,files,rss' },
        cancelToken: this.cancelToken.token
      })
        .then((response) => {
          this.stopLoad()

          const newsroom = new Newsroom(response.data.data)

          this.newsroom = newsroom

          if (!this.hasStories && this.$route.name !== 'newsroom.about') {
            return this.$router.push({ name: 'newsroom.about' })
          }

          changePageTitle(newsroom.name)
          changePageDescription(newsroom.description)
          this.changePageMetaTags(newsroom)
        })
        .catch((error) => {
          this.stopLoadWithFail()
          if (this.$api.isCancelToken(error)) return
          this.$displayRequestError(error, this.$t('errors.error'), 5000, [404]) // skip reporting 404's
          this.$router.push(this.$i18nRoute({ name: 'home' }))
        })
    },

    changePageMetaTags (newsroom) {
      changeOpenGraphTag('title', newsroom.name)
      changeOpenGraphTag('type', 'business.business')
      changeOpenGraphTag('url', process.env.HYPENEWS_URL + this.$route.fullPath)

      if (this.$safeGet(newsroom, 'logo.cropped', false)) {
        changeOpenGraphTag('image', newsroom.logo.cropped)
      }

      changeOpenGraphTag('description', newsroom.description)
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.company-page {
  padding-bottom: 2rem;

  &__content-wrap {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
  }

  &__content {
    width: 100%;
  }

  &__name {
    margin-top: 20px;
    @include tablet {
      margin-top: 100px;
    }
  }

  .AlternativeNewsroomPicker {
    margin-top: $spacing-xxlarge;
  }

  .content-wrapper {
    .company-page-block {
      border-bottom: $hf__border--base;
      margin-bottom: 1rem;

      &:last-child {
        border: none;
      }
    }
  }
}

@include tablet {
  .company-page {
    &__name {
      margin-top: 50px;
    }

    .AlternativeNewsroomPicker {
      position: absolute;
      right: 0;
      top: $margin-large;
      margin-top: 0;
      @include rtl {
        left: 0;
        right: auto;
      }
    }

    .content-wrapper {
      padding: 2rem 0;
      flex: 1 1 auto;
    }
  }
}

</style>
