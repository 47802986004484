<template>
  <div
    class="AlternativeNewsroomPicker is-flex can-wrap is-aligned-center is-aligned-middle"
    data-testid="AlternativeNewsroomPicker"
  >
    <div
      class="is-flex is-aligned-middle"
      data-testid="AlternativeNewsroom__CurrentNewsroom"
    >
      <country-flag :country="newsroom.country.iso_alpha2" />
      {{ newsroom.country.name }}
    </div>
    <span
      v-if="siblingNewsrooms.length"
      data-testid="AlternativeNewsroom__Separator"
      class="p-l-s"
    >|</span>
    <div
      v-for="siblingNewsroom in siblingNewsrooms"
      :key="siblingNewsroom.id"
      class="p-l-s"
      data-testid="AlternativeNewsroom"
    >
      <router-link
        :to="{ name: routeTo, params:{ newsroomSlug: siblingNewsroom.slug } }"
        class="is-flex is-aligned-middle is-aligned-center"
        data-testid="AlternativeNewsroom_Link"
      >
        <country-flag :country="siblingNewsroom.country.iso_alpha2" />
      </router-link>
    </div>
  </div>
</template>

<script>

/**
 * @module AlternativeNewsroomPicker
 */
import { NewsroomService } from '@hypefactors/shared/js/services/api/NewsroomService'
import { Newsroom } from '@hypefactors/shared/js/models/Newsroom'

export default {
  name: 'AlternativeNewsroomPicker',
  props: {
    newsroom: {
      type: Object,
      required: true
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isFetching: false,
      /** @type Newsroom[] */
      siblingNewsrooms: []
    }
  },
  computed: {
    routeTo () {
      return this.editMode ? 'newsroom.edit' : 'newsroom.stories'
    }
  },
  watch: {
    'newsroom.id': {
      immediate: true,
      handler: 'fetchNewsroomSlugs'
    }
  },
  methods: {
    async fetchNewsroomSlugs () {
      this.isFetching = true
      try {
        let response

        response = await NewsroomService.getNewsroomSiblings(this.newsroom.id, { params: { include: 'country' } })

        this.siblingNewsrooms = response.map(n => new Newsroom(n))
      } catch (err) {
        console.log(err)
        this.$displayRequestError(err)
      } finally {
        this.isFetching = false
      }
    }
  }
}
</script>
