<template>
  <li class="NewsroomNavigationItem">
    <router-link
      :to="to"
      :title="title"
      exact
      class="NewsroomNavigationItem__link"
      active-class="is-active"
    >
      <div class="NewsroomNavigationItem__icon">
        <v-icon :type="icon" />
      </div>

      <div class="NewsroomNavigationItem__text">
        {{ text }}
      </div>
    </router-link>
  </li>
</template>

<script>
/**
 * @module NewsroomNavigationItem
 */
export default {
  name: 'NewsroomNavigationItem',
  props: {
    to: {
      type: Object,
      required: true
    },
    icon: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.NewsroomNavigationItem {
  position: relative;
  padding: 1rem 0;
  flex: 1 1 0;
  text-align: center;
  text-transform: uppercase;

  &:first-child {
    padding-left: 0;
  }

  &__link {
    //opacity: 0.5;

    &.is-active {
      opacity: 1;
      color: $primary;
    }
  }

  &__icon {
    display: block;
  }

  &__text {
    display: block;
    line-height: 2rem;
  }
}

@include tablet {
  .NewsroomNavigationItem {
    padding: 0.8rem 2.5rem;
    flex: 0 0 auto;

    &__icon {
      display: none;
    }

    &__link {
      font-weight: 600;
    }
  }
}
</style>
