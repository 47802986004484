<template>
  <el-popover
    class="NewsroomRss"
    trigger="click"
  >
    <ul
      class="NewsroomRss__feedList m-l-m"
      data-testid="NewsroomRss__feedList"
    >
      <li
        v-for="(feed, type) in feeds"
        :key="type"
      >
        <a
          :href="feed"
          target="_blank"
        >
          {{ $t('components.newsroom_rss_feed.types.' + type) }}
        </a>
      </li>
    </ul>
    <template slot="reference">
      <span
        class="icon m-r-s is-cursor-pointer"
        data-testid="NewsroomRssTrigger"
      >
        <i class="hf hf-lg hf-feed" />
      </span>
    </template>
  </el-popover>
</template>

<script>
export default {
  name: 'NewsroomRss',
  props: {
    newsroom: {
      type: Object,
      required: true
    }
  },
  computed: {
    feeds () {
      return this.newsroom.rss
    }
  }
}
</script>
