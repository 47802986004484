<template>
  <newsroom-navigation-base :newsroom="newsroom">
    <template slot-scope="{ hasAboutInfo, hasAssets, hasStories }">
      <newsroom-navigation-item
        v-if="hasStories"
        :to="{ name: 'newsroom.stories', params: { newsroomSlug: newsroom.slug } }"
        :text="$t('components.company_navigation.company_news')"
        icon="file-text-o"
      />
      <newsroom-navigation-item
        :title="newsroom.name"
        :class="{ 'is-hidden-tablet': !hasAboutInfo }"
        :text="$t('components.company_navigation.social')"
        :to="{ name: 'newsroom.about', params: { newsroomId: newsroom.id, newsroomSlug: newsroom.slug } }"
        icon="user-circle-o"
      />
      <newsroom-navigation-item
        v-if="hasAssets"
        :to="{ name: 'newsroom.assets', params: { newsroomId: newsroom.id, newsroomSlug: newsroom.slug } }"
        :text="$t('components.company_navigation.company_assets')"
        icon="files"
      />
    </template>
  </newsroom-navigation-base>
</template>

<script>
import NewsroomNavigationItem from './NewsroomNavigationItem'
import NewsroomNavigationBase from './NewsroomNavigationBase'

export default {
  name: 'NewsroomNavigation',
  components: { NewsroomNavigationBase, NewsroomNavigationItem },
  props: {
    newsroom: {
      type: Object,
      required: true
    }
  }
}
</script>
